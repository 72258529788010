import { MediaContentType } from '../../api/content/types/content-type';
import { isDefined } from '../../core/std/types';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import type { AnalyticEventSender } from '../index';
import { getAnalyticContentTypeValue } from './get-kmza-content-type-value';
import { GAValue } from './kmza';
import { ItemPageOptions } from './use-content-page-kmza';

const getPayload = ({
  title,
  contentType,
  season,
  episode,
  from,
  playlistTitle,
  kinomTitle,
}: ItemPageOptions): GAValue[] => {
  let payload: GAValue[] = [
    {
      label: AnalyticPayloadLabelName.ItemTitle,
      value: title,
    },
    {
      label: AnalyticPayloadLabelName.ItemType,
      value: getAnalyticContentTypeValue(contentType),
    },
  ];

  if (contentType !== MediaContentType.Movie) {
    payload = [
      ...payload,
      {
        label: AnalyticPayloadLabelName.Season,
        value: season || 0,
      },
      {
        label: AnalyticPayloadLabelName.Episode,
        value: episode || 0,
      },
    ];
  }

  if (isDefined(kinomTitle)) {
    payload.push({ label: AnalyticPayloadLabelName.KinomTitle, value: kinomTitle });
  }

  if (from) {
    payload.push({
      label: AnalyticPayloadLabelName.From,
      value: from,
    });
  }

  if (playlistTitle) {
    payload.push({
      label: AnalyticPayloadLabelName.PlaylistTitle,
      value: playlistTitle,
    });
  }

  return payload;
};

interface TvPlayerPageOptions {
  channelTitle: string;
}

export function usePlayerPageAnalytics(sender: AnalyticEventSender) {
  const onGotoPlayerPage = (options: ItemPageOptions) => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.GotoPlayerPage,
      values: getPayload(options),
      options: { itemId: options.itemId, kinomId: options.kinomId, episodeId: options.episodeId },
    });
  };

  const onShowPlayerPage = (options: ItemPageOptions) => {
    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.ShowPlayerPage,
      values: getPayload(options),
      options: { itemId: options.itemId, kinomId: options.kinomId, episodeId: options.episodeId },
    });
  };

  const onShowTvPlayerPage = (options: TvPlayerPageOptions) => {
    const values: GAValue[] = [
      { label: AnalyticPayloadLabelName.ItemType, value: 'tvchannel' },
      { label: AnalyticPayloadLabelName.ItemTitle, value: options.channelTitle },
    ];

    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.ShowPlayerPage,
      values,
    });
  };

  const onGotoTvPlayerPage = (options: TvPlayerPageOptions) => {
    const values: GAValue[] = [
      { label: AnalyticPayloadLabelName.ItemType, value: 'tvchannel' },
      { label: AnalyticPayloadLabelName.ItemTitle, value: options.channelTitle },
    ];

    sender.sendAnalyticEvent({
      name: AnalyticEventNameNew.GotoPlayerPage,
      values,
    });
  };

  return {
    onGotoTvPlayerPage,
    onGotoPlayerPage,
    onShowTvPlayerPage,
    onShowPlayerPage,
  };
}
