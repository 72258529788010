import { globalSettings } from '../services';
import { tPlural, translate } from '../services/localization/translate';

export enum AvailabilityStatus {
  Available = 'available',
  Unavailable = 'unavailable',
  UnavailableSoon = 'unavailable_soon',
  AvailableSoon = 'available_soon',
  TimeShiftAvailable = 'timeshift_available',
}

const statuses = [AvailabilityStatus.Unavailable, AvailabilityStatus.UnavailableSoon, AvailabilityStatus.AvailableSoon];

const daysLeft = (date: string): number => {
  const dayInMilliseconds = 1000 * 60 * 60 * 24;

  return Math.round((new Date(date).getTime() - new Date().setHours(0, 0, 0, 0)) / dayInMilliseconds);
};

interface GetAvailabilityMessageOptions {
  isActiveSubscription?: boolean;
  withHourOrMinute?: boolean;
  dateFormat?: string;
}

export function useContentAvailability() {
  const getUnavailableSoonText = (daysCount: number): string | string[] => {
    return tPlural('availability.unavailableSoon', daysCount, { days: daysCount });
  };

  const getAvailableSoonText = (date: string, time: string, withHourOrMinute: boolean): string | string[] => {
    if (withHourOrMinute) {
      return translate('pages.mediaCard.availability.availableSoonDateAndTime', { date, time });
    }

    return translate('pages.mediaCard.availability.availableSoonDate', { date });
  };

  const isUnavailable = (content?: any) =>
    content?.availability === AvailabilityStatus.Unavailable ||
    content?.availability === AvailabilityStatus.TimeShiftAvailable;

  const isAvailableSoon = (content?: any) => content?.availability === AvailabilityStatus.AvailableSoon;

  const isUnavailableSoon = (content?: any) => content?.availability === AvailabilityStatus.UnavailableSoon;

  const isAvailable = (content?: any) => content?.availability === 'available' || isUnavailableSoon(content);

  const getAvailabilityMessage = (content?: any, options: GetAvailabilityMessageOptions = {}): string | string[] => {
    const { isActiveSubscription, withHourOrMinute, dateFormat } = options;

    switch (content?.availability) {
      case AvailabilityStatus.Unavailable:
      case 'timeshift_available':
        return content.licenseLockedInfo || translate('pages.mediaCard.availability.unavailable');
      case AvailabilityStatus.UnavailableSoon:
        return isActiveSubscription ? getUnavailableSoonText(daysLeft(content.licenseDateEnd)) : '';
      case AvailabilityStatus.AvailableSoon: {
        const licenseDateStart = globalSettings.dateFns.parseISO(content.licenseDateStart);

        const hour = globalSettings.dateFns.getHours(licenseDateStart);
        const minute = globalSettings.dateFns.getMinutes(licenseDateStart);
        const hasHourOrMinute = withHourOrMinute ? Boolean(hour || minute) : false;

        const date = globalSettings.dateFns.format(licenseDateStart, dateFormat || 'dd.MM.yyyy');
        const time = globalSettings.dateFns.format(licenseDateStart, 'H:mm');

        return getAvailableSoonText(date, time, hasHourOrMinute);
      }
      default:
        return '';
    }
  };

  const showAvailabilityOverlay = (content: any) => {
    return statuses.includes(content?.availability as AvailabilityStatus);
  };

  return {
    getAvailabilityMessage,
    isAvailable,
    isUnavailable,
    isAvailableSoon,
    isUnavailableSoon,
    showAvailabilityOverlay,
  };
}
