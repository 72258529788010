<template>
  <div>
    <div ref="el" :class="$style.wrapper">
      <AppMenu v-if="!isMenuHidden" ref="appMenuComp" />
      <AppBuildBadge v-if="isBuildBadgeShown" />
      <div
        :class="{
          [$style.page]: true,
          [$style.pageMenuExpanded]: hasFocusedMenu,
        }"
      >
        <RouterView></RouterView>
      </div>
      <UIAlert />
    </div>
    <portal-target name="alerts"></portal-target>
    <portal-target name="modals"></portal-target>
  </div>
</template>

<script>
import { isDefined } from '@package/sdk/src/core';
import useVueFocusableHook from '@package/smarttv-navigation/src/useVueFocusableHook';
import {
  channelsService,
  contentCacheManager,
  RouterPage,
  routerService,
  storeToRefs,
  useContentStore,
  useMainPageStore,
  useSessionStore,
  useTvChannelsStore,
} from '@SMART/index';
import { computed, defineComponent, provide, ref, watch } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';

import AppBuildBadge from '@/components/app-build-badge/AppBuildBadge.vue';
import AppMenu from '@/components/menu/AppMenu.vue';

import UIAlert from './components/alert/UIAlert.vue';

export default defineComponent({
  components: {
    AppMenu,
    AppBuildBadge,
    UIAlert,
  },
  setup(_, context) {
    const contentStore = useContentStore();
    const sessionStore = useSessionStore();
    const tvChannelsStore = useTvChannelsStore();
    const mainPageStore = useMainPageStore();

    const { profile } = storeToRefs(sessionStore);

    const route = computed(() => context.root?.$route);

    const appMenuComp = ref();

    const hasFocusedMenu = computed(() => appMenuComp.value?.hasFocusedChild);

    const { el, focusKey } = useVueFocusableHook({
      focusKey: 'APP_WRAPPER',
      trackChildren: true,
      focusable: true,
      autoRestoreFocus: false,
      saveLastFocusedChild: false,
      preferredChildFocusKey: null,
      isFocusBoundary: false,
    });

    provide('parentFocusKey', focusKey.value);

    const isMenuHidden = ref(false);
    const cacheKey = ref(uuidv4());

    const isBuildBadgeShown = computed(
      () => route.value.path.includes('settings') || route.value.path.includes('auth'),
    );

    const hiddenAppMenuRoutes = [
      RouterPage.MediaCardPlayerPage,
      RouterPage.ChannelsPlayerPage,
      RouterPage.ProfilesPage,
      RouterPage.MediaCardSeriesPage,
      RouterPage.Offers,
      RouterPage.ParentalPage,
    ];

    const cachedRoutes = [
      RouterPage.MediaCardControls,
      RouterPage.MediaCardDescription,
      RouterPage.MediaCardPersons,
      RouterPage.CatalogPage,
      RouterPage.CollectionPage,
      RouterPage.SearchPage,
      RouterPage.ChannelsPage,
      RouterPage.ChannelsPlayerPage,
      RouterPage.Offers,
      RouterPage.OfferInfo,
      RouterPage.MyChannelPage,
      RouterPage.AuthPage,
      RouterPage.MediaCardSimilarPage,
    ];

    const onDidProfileUpdated = () => {
      contentStore.reset();
      tvChannelsStore.reset();
      mainPageStore.reset();

      channelsService.fetchChannels();
      contentStore.fetchGenres();
      contentStore.fetchCountries();
      contentStore.fetchPeriods();
      sessionStore.fetchOffers();
      contentCacheManager.clear();

      cacheKey.value = uuidv4();
    };

    const onBeforeEach = (to, from, next) => {
      if (isDefined(to.name) && hiddenAppMenuRoutes.includes(to.name)) {
        isMenuHidden.value = true;
        next();
        return;
      }

      if (isDefined(to.name) && isDefined(from.name) && !cachedRoutes.includes(to.name)) {
        cacheKey.value = uuidv4();
      }

      isMenuHidden.value = false;
      next();
    };

    routerService.addBeforeEach(onBeforeEach);
    watch(() => profile.value?.kind, onDidProfileUpdated);

    return {
      appMenuComp,
      el,
      isMenuHidden,
      isBuildBadgeShown,
      cacheKey,
      hasFocusedMenu,
    };
  },
});
</script>

<style module lang="scss">
.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.page {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: left;
  transition: left 0.2s ease-in-out;
}

.pageMenuExpanded {
  left: 160px;
}
</style>
