import { render, staticRenderFns } from "./IconVijuTv.vue?vue&type=template&id=856656fc"
import script from "./IconVijuTv.vue?vue&type=script&lang=js"
export * from "./IconVijuTv.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.3_babel-core@7.0.0-bridge.0_@babel+core@7.25.2__css-_dp4yflhqh7jsubsbxx3bcpwy5m/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports